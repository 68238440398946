import * as React from "react";
import { Meta, Menu, Footer } from "../components";

const Index = () => {
  return(
    <>
      <Meta />
      <Menu />
      <Footer />
    </>
  )
}

export default Index;